import {
  useCallback,
  type ChangeEventHandler,
  type FormEventHandler,
  type Dispatch,
  type SetStateAction,
} from 'react';
import clsx from 'clsx';

import { Button, Content, Field, Typography } from 'shared/ui';
import { User } from 'shared/types';

import styles from './Editor.module.css';

interface EditorProps {
  loading: boolean;
  posting: boolean;
  value: User;
  error?: string;
  onChange: Dispatch<SetStateAction<User>>;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export const Editor = ({
  loading,
  posting,
  value,
  error,
  onChange,
  onSubmit,
}: EditorProps) => {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const { name, value } = e.currentTarget;
      onChange((current) => ({
        ...current,
        [name]: value,
      }));
    },
    [onChange]
  );
  return (
    <form
      className={clsx(styles.container, loading ? styles.loading : null)}
      onSubmit={onSubmit}
    >
      <Content>
        <Field
          name="login"
          caption="Логин"
          required={false}
          value={value.login || ''}
          onChange={handleChange}
        />
        <Field
          name="email"
          caption="Электронная почта"
          required={false}
          value={value.email || ''}
          onChange={handleChange}
        />
        {/*<Field
          name="email"
          caption="Резервная почта"
          value={value.additional_email || ''}
          onChange={handleChange}
  />*/}
        <Field
          name="name"
          caption="Фамилия"
          required={false}
          value={value.name || ''}
          onChange={handleChange}
        />
        <Field
          name="givenname"
          caption="Имя"
          required={false}
          value={value.givenname || ''}
          onChange={handleChange}
        />
        <Field
          name="surname"
          caption="Отчество"
          required={false}
          value={value.surname || ''}
          onChange={handleChange}
        />
        <Field
          name="phone"
          caption="Телефон"
          required={false}
          value={value.phone || ''}
          onChange={handleChange}
        />
        <Field
          name="mobile_phone"
          caption="Мобильный телефон"
          required={false}
          value={value.mobile_phone || ''}
          onChange={handleChange}
        />
        <div className={styles.actions}>
          <Button color="primary" type="submit" loading={posting}>
            Сохранить
          </Button>
        </div>
        <div>
          {error ? (
            <Typography.Text color="error">{error}</Typography.Text>
          ) : null}
        </div>
      </Content>
    </form>
  );
};
